import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(150,0,0,0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  &:hover {
    background: rgba(150,0,0,0.5);
  }
`
const Msg = styled.p`
  color: #fff;
  font-family: 'arvo';
  font-size: 1.2em;
`

const Error = ({ errorMsg, closeError }) => (
  <Wrapper onClick={closeError}>
    <Msg>{errorMsg}</Msg>
  </Wrapper>
)

export default Error;
