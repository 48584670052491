import React, { Component } from 'react';
import Cookies from 'js-cookie'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import styled from 'styled-components';
import EventInfo from './eventInfo';
import Loading from '../loading';
import Admin from './admin';
import Response from './response';
import SendResponse from './sendResponse';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`
const Decided = styled.div`
  padding: 20px;
  background: rgba(255,255,255,0.7);
  color: #333;
  position: fixed;
  bottom: 80px;
  left: 20px;
  right: 20px;
  cursor: pointer;
  &:hover {
    background: rgba(255,255,255,0.3);
  }
`

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      loading: true,
      hasImage: false,
      hosts: '',
      place: '',
      text: '',
      timestamp: 0,
      title: '',
      imgSrc: '',
      showResp: false,
      resp: '',
      respCookie: '',
      decided: false,
      showAdmin: false
    }
  }

  componentDidMount() {
    this.getEvent();
    this.checkAdmin();
    const resp = Cookies.get(this.props.match.params.eventKey);
    if (resp !== undefined) {
      this.setState({ respCookie: resp });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getEvent();
    }
  }

  checkAdmin = () => {
    const k = this.props.match.params.eventKey;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        firebase.database().ref(`/user/${uid}`).once('value').then(snap => {
          snap.forEach(event => {
            if (event.val().eventId === k) {
              this.setState({ admin: true });
            }
          });
        });
      }
    });
  }

  getEvent = () => {
    const k = this.props.match.params.eventKey;
    const ref = firebase.database().ref(`events/${k}`);
    ref.once('value').then(snap => {
      console.log(snap.val());
      const { hasImage, hosts, place, text, timestamp, title, guests, updated, lat, lon } = snap.val();
      this.setState({ hasImage, hosts, place, text, timestamp, title, guests, updated, lat, lon });
      if (hasImage) {
        const ref = firebase.storage().ref(`/events/${k}/cover`);
        ref.getDownloadURL().then(imgSrc => {
          this.setState({ loading: false, imgSrc });
          console.log(imgSrc);
        })
        .catch(e => console.log(e, 'error when fetching image'));
      } else {
        this.setState({ loading: false });
      }
    })
    .catch(e => console.log(e));
  }

  respond = (resp) => {
    // resp is 'going' or 'cant'
    // resp is 'decided' if guest has already decided
    if (resp === 'decided') {
      this.setState({ decided: true });
    } else if (resp === 'admin') {
      this.setState({ showAdmin: true });
    } else {
      this.setState({ resp, showResp: true });
    }
  }

  hideResp = () => {
    this.setState({ showResp: false, showAdmin: false });
  }

  toggleDecided = () => {
    this.setState({ decided: false });
  }

  setCookieResp = (resp) => {
    this.setState({ respCookie: resp });
  }

  reload = () => {
    this.getEvent();
  }

  render() {
    const { eventKey } = this.props.match.params;
    const {
      hasImage, hosts, place, text, timestamp, title, imgSrc, resp, showResp, respCookie, decided, showAdmin, guests, admin, updated, lat, lon
    } = this.state;
    return (
      <Wrapper>
        { showResp && <SendResponse cookieResp={this.setCookieResp} hideResp={this.hideResp} resp={resp} eventKey={eventKey} hosts={hosts} /> }
        { decided && <Decided onClick={() => this.toggleDecided()}>You already decided ({respCookie}) If you changed your mind message '{hosts}' directly.</Decided> }
        { showAdmin && <Admin guests={guests} hideResp={this.hideResp} />}
        { this.state.loading ? <Loading /> :
        <InnerWrapper>
          <EventInfo
            hasImage={hasImage}
            hosts={hosts}
            place={place}
            text={text}
            timestamp={timestamp}
            title={title}
            imgSrc={imgSrc}
            admin={admin}
            eventId={eventKey}
            reload={this.reload}
            updated={updated}
            lat={lat}
            lon={lon}
          />
          <Response admin={admin} respCookie={respCookie} respond={this.respond} />
        </InnerWrapper>
        }
      </Wrapper>
    );
  }
}

export default Event;
