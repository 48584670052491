import React from 'react';
import styled from 'styled-components';
import DateTimePicker from '../dateTimePicker';
import Label from './label';

const Wrapper = styled.div`
`

const CreateDate = ({ onValChange, nextStep, event }) => {
    
    const onClickDone = (timestamp) => {
        onValChange('date', timestamp);
        nextStep(true);
    }

    return (
        <Wrapper>
            <Label>When is your event?</Label>
            <DateTimePicker initVal={event.date} onClickDone={onClickDone} />
        </Wrapper>
    );
};

export default CreateDate;