import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Cancel, Save, Search } from 'styled-icons/material';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import SingleLoading from '../singleLoading';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    overflow-y: auto;
    z-index: 9999;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Input = styled.input`
  color: #fff;
  width: 90%;
  max-width: 400px;
  margin: 10px;
  background: #111;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  display: block;
  font-size: 1em;
  font-family: 'arvo';
  border: 0;
`
const Btn = styled.div`
    cursor: pointer;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #A8BCB3;
    color: #222;
`
const BtnClose = styled(Btn)`
    background: #aaa;
`
const BtnWrap = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 300px;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
`
const SearchResults = styled.div`
    position: fixed; 
    top: 70px;
    z-index: 9999;
    overflow-y: auto;
`
const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
const SearchBtn = styled.div`
    cursor: pointer;
    padding: 8px;
    background: #333;
    border-radius: 7px;
    &:hover {
        background: #222;
    }
`

const SearchResult = styled.div`
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    width: 90%;
    margin: 5px auto;
    background: #333;
    &:hover {
        background: #222;
    }
`
const Icon = styled.img`
    margin-left: 7px;
`

const EditPlace = ({ place, toggleEdit, eventId, reloadEvent }) => {
    const [textVal, setTextVal] = useState(place);
    const [isSaving, setIsSaving] = useState(false);
    const [searchRes, setSearchRes] = useState([]);
    const [latLon, setLatLon] = useState([0,0]);
    const [mapName, setMapName] = useState('');
    const [center, setCenter] = useState([47.995684, 7.852928]);

    const onChange = (e) => {
        setTextVal(e.target.value);
    }

    const onSearch = () => {
        fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${textVal}`)
        .then(res => res.json())
        .then(
            (result) => {
                setSearchRes(result);
            }
        )
    }

    const onSave = () => {
        setIsSaving(true);
        const updated = Date.now();
        const ref = firebase.database().ref(`events/${eventId}`);
        const lat = latLon[0];
        const lon = latLon[1];
        ref.update({ place: textVal, updated, lat, lon })
        .then(() =>{
            reloadEvent();
            toggleEdit();
            setIsSaving(false);   
        })
        .catch(e => setIsSaving(false));
    }

    const onSearchResult = (res) => {
        // res. ... lat, lon, display_name 
        setLatLon([res.lat, res.lon]);
        setMapName(res.display_name);
        setCenter([res.lat, res.lon]);
        setSearchRes([]);
    }

    const onSearchResultWithoutMap = () => {
        setLatLon([0,0]);
        setSearchRes([]);
    }

    return (
        <div>
            {   isSaving ?
                <Wrapper>
                    <SingleLoading />
                </Wrapper>:
                <Wrapper className="animated fadeInUp">
                    <SearchWrapper>
                        <Input value={textVal} onChange={(e) => onChange(e)} />
                        <SearchBtn onClick={() => onSearch()}><Search size={24} color="#fff" /></SearchBtn>
                    </SearchWrapper>
                    <Map center={center} zoom={12}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        { latLon[0] !== 0 && <Marker position={latLon}><Popup>{mapName}</Popup></Marker> }
                    </Map>
                    <BtnWrap>
                        <BtnClose onClick={toggleEdit}>
                            <Cancel color="#333" size={24} /> Cancel
                        </BtnClose>
                        <Btn onClick={onSave}>
                            <Save color="#333" size={24} /> Save
                        </Btn>
                    </BtnWrap>
                    { searchRes.length > 0 &&
                    <SearchResults>
                        {searchRes.map(res => {
                            console.log(res);
                            return (
                                <SearchResult key={res.osm_id} onClick={() => onSearchResult(res)}>
                                    {res.display_name}
                                    {res.icon && <Icon src={res.icon} />}
                                </SearchResult>
                            )
                        })}
                        <SearchResult onClick={() => onSearchResultWithoutMap()}>
                            Use {textVal} (without map)
                        </SearchResult>
                    </SearchResults>
                    }
                </Wrapper>
            }
        </div>
    );
}

export default EditPlace;