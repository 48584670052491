import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

const Wrapper = styled.div`
  max-width: 400px;
  margin: 20px auto 0 auto;
  padding: 20px;
  box-sizing: border-box;
`
const Input = styled.input`
  border: 0;
  background: rgba(0,0,0,0.5);
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  border-radius: 10px;
`
const Text = styled.p`

`
const QRWrap = styled.div`
  text-align: center;
  width: 100%;
`

const handleFocus = (event, copySuccess) => {
  event.target.select();
  document.execCommand('copy');
  event.target.focus();
  copySuccess();
};

const ShareLink = ({ eventKey, copySuccess }) => (
  <Wrapper>
    <Text>Invite guests with the following link. Click on it to copy.</Text>
    <Input type="text" readOnly value={`${window.location.host}/e/${eventKey}`} onFocus={e => handleFocus(e, copySuccess)} />
    <Text>Show them this QR-Code:</Text>
    <QRWrap>
      <QRCode value={`${window.location.host}/e/${eventKey}`} />
    </QRWrap>
  </Wrapper>
);

export default ShareLink;
