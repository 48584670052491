import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 400px;
  height: ${props => props.imgSrc === '' ? 'auto' : '200px'};
  margin: auto;
  border-radius: 10px;
  width: 90%;
  background: rgba(0,0,0,0.5);
  padding: 20px;
  box-sizing: border-box;
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
`

const InfoWrapper = styled.div`
  padding: 10px;
  box-sizing: border-box;
  background: ${props => props.imgSrc === '' ? 'none' : 'rgba(0,0,0,0.8)'};;
`

const Title = styled.p`
  font-size: 1.2em;
  color: #fff;
  margin: 0;
`
const Date = styled.p`
  color: #fff;
  margin: 0;
`

const EventPreview = ({ hasImage, hosts, place, text, timestamp, title, imgSrc }) => (
  <Wrapper imgSrc={imgSrc}>
    <InfoWrapper imgSrc={imgSrc}>
      <Title>{title}</Title>
      <Date>{moment(timestamp).format('dddd,  DD. MMM ´YY HH:mm')}</Date>
    </InfoWrapper>
  </Wrapper>
)

export default EventPreview;
