import React from 'react';
import styled from 'styled-components';
import { AddCircle } from 'styled-icons/material';
import { Link } from 'react-router-dom';
import LoginSignup from '../loginSignup';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const Button = styled(Link)`
  color: #fff;
  background: rgba(0,0,0,0.5);
  border-radius: 60px;
  padding: 20px;
  display: block;
  text-decoration: none;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &:hover {
    background: rgba(0,0,0,0.8);
  }
`


const NoEvents = () => (
    <Wrapper>
        <p>You have no events, yet. Go ahead and create one:</p>
        <Button to="/createEvent" className="animated fadeInUp">
            <AddCircle color="#fff" size={40} style={{ marginRight: 5 }} /> Create
        </Button>
        <p>Or if you have an account Login:</p>
        <LoginSignup />
    </Wrapper>
);

export default NoEvents;