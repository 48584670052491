import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Home from './home/home';
import Create from './create/create';
import Privacy from './privacy';
import CreateEvent from './createEvent';
import Share from './share/share';
import Event from './event/event';
import Header from './header';
import Dashboard from './dashboard';
import Footer from './footer';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`


const Router = () => (
  <Wrapper>
    <Header />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/create" component={Create} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/createEvent" component={CreateEvent} />
      <Route path="/share/:eventKey" component={Share} />
      <Route path="/e/:eventKey" component={Event} />
      <Route path="/dashboard" component={Dashboard} />
    </Switch>
    <Footer />
  </Wrapper>
);

export default Router;
