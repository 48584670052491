import React from 'react';
import styled from 'styled-components';
import { MapPin2 } from 'styled-icons/remix-line/';

const Wrapper = styled.div`
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 0px 20px 0px 5px;
  text-align: center;
  display: flex;
  flex: 1;
  box-sizing: border-box;
`

const I = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
`

const Input = styled.input`
  flex: 1;
  border-radius: 10px;
  background: rgba(0,0,0,0.5);
  padding: 20px;
  border: 0;
  font-size: 15px;
  color: #fff;
  font-family: 'Arvo';
  margin: auto;
  box-sizing: border-box;
`

const EventPlace = ({ place, changePlace }) => (
  <Wrapper>
    <I>
      <MapPin2 size={20} color="#fff" />
    </I>
    <Input
      type="text"
      value={place}
      onChange={changePlace}
      placeholder="Where is the event?"
    />
  </Wrapper>
)

export default EventPlace;
