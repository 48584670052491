import React from 'react';
import styled from 'styled-components';
import { Information } from 'styled-icons/remix-line';


const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 0px 20px 0px 5px;
  text-align: center;
  display: flex;
  flex: 1;
  box-sizing: border-box;
`
const I = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
`

const Input = styled.textarea`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  background: none;
  border: 0;
  font-size: 15px;
  color: #fff;
  font-family: 'Arvo';
  background: rgba(0,0,0,0.5);
  margin: 20px auto;
  border-radius: 10px;
  height: 230px;
`

const EventImage = ({ text, changeText }) => (
  <Wrapper>
    <I>
      <Information size={20} color="#fff" />
    </I>
    <Input
      type="text"
      onChange={changeText}
      placeholder="Describe your event and give your friends more informations. You can still edit this later and add updates."
      value={text}
    />
  </Wrapper>
)

export default EventImage;
