import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

`
const Text = styled.p`
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 1.8em;
    text-align: center;
`

const Label = ({ children }) => (
    <Wrapper>
        <Text>{ children }</Text>
    </Wrapper>
);

export default Label;