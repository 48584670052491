import React, { useState } from 'react';
import styled from 'styled-components';
import CreateSteps from './createSteps';
import ShowEvent from './showEvent';
import JumpTo from './jumpTo';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Index = () => {
    const [event, setEvent] = useState({});
    const [currentStep, setCurrentStep] = useState(0);

    const stepId = {
        0: 'title',
        1: 'place',
        2: 'host',
        3: 'date',
        4: 'image',
        5: 'text'
    }

    const setStep = (i, noCheck) => {
        // before going to nextStep check if currentstep is defined.
        if (noCheck) {
            setCurrentStep(i);
        } else if (event[stepId[i-1]] !== undefined) {
            setCurrentStep(i);
        }
    }

    const onValChange = (id, value) => {
        const newObject = { ...event };
        newObject[id] = value;
        setEvent(newObject);
    }

    return (
        <Wrapper>
            <ShowEvent setStep={setStep} event={event} />
            <CreateSteps event={event} onValChange={onValChange} currentStep={currentStep} setStep={setStep} />
            <JumpTo setStep={setStep} currentStep={currentStep} />
        </Wrapper>
    );
}

export default Index;