import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import TimeKeeper from 'react-timekeeper';
import InfiniteCalendar from 'react-infinite-calendar';
import Overlay from './overlay';

const Wrapper = styled.div`
`
const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const DateTimeWrapper = styled.div`
    max-width: 200px;
    width: 90%;
    border-radius: 8px;
    margin: 20px auto 0 auto;
    overflow: hidden;
`
const DateView = styled.div`
    width: 100%;
    background: #111;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 1.2em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: rgba(255,255,255,0.2);
    }
`
const TimeView = styled(DateView)`
    background: #666;
    color: #000;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`
const DoneView = styled.div`
    background: #ddd;
    color: #333;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    box-sizing: border-box;transition: all 0.3s ease-in-out;
    &:hover {
        background: #fff;
    }
`

const Hr = styled.hr`
    border: solid 1px #999;
    margin: 0;
`
const Done = styled.div`
    width: 300px;
    background: #ddd;
    padding: 10px;
    color: #333;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
`

const DateTimePicker = ({ onClickDone, initVal }) => {

    useEffect(() => {
        if (initVal !== undefined) {
            const date = new Date(initVal);
            const obj = {};
            obj.formatted24 = moment(initVal).format('HH:mm');
            obj.hour = date.getHours(); 
            obj.minute = date.getMinutes(); 
            setTime(obj);
            setDate(date);
        } else {
            setIsDatePickerOpen(true);
        }
    }, [initVal])
    
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [time, setTime] = useState(0);
    const [date, setDate] = useState(0);
    const [error, setError] = useState(false);

    const today = new Date();

    const dateChange = (date) => {
        setDate(date);
    }
    const timeChange = (time) => {
        setTime(time);
    }

    const onDone = () => {
        if (time === 0 || date === 0) {
            setError(true);
            setTimeout(() => { setError(false); }, 1000);
        } else {
            const finalDate = date;
            finalDate.setHours(time.hour);
            finalDate.setMinutes(time.minute);
            onClickDone(finalDate.getTime());
        }
    }

    return (
        <Wrapper>
            <DateTimeWrapper className={error && 'animated shake'}>
                <DateView onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
                    { 
                        date === 0 ? 'Select Date' :
                        moment(date).format('DD. MMM (ddd)')
                    }
                </DateView>
                <Hr />
                <TimeView onClick={() => setIsTimePickerOpen(!isTimePickerOpen)}>
                    { 
                        time === 0 ? 'Select Time' :
                        time.formatted24
                    }
                </TimeView>
                <DoneView onClick={() => onDone()}>
                    Done
                </DoneView>
            </DateTimeWrapper>
            { isTimePickerOpen &&
                <Overlay onClose={() => setIsTimePickerOpen(!isTimePickerOpen)}>
                    <InnerWrapper>
                        <TimeKeeper
                            switchToMinuteOnHourSelect
                            hour24Mode
                            onDoneClick={() => setIsTimePickerOpen(!isTimePickerOpen)}
                            onChange={(newTime) => timeChange(newTime)}
                        />
                    </InnerWrapper>
                </Overlay>
            }
            { isDatePickerOpen &&
                <Overlay onClose={() => setIsDatePickerOpen(!isDatePickerOpen)}>
                    <InnerWrapper>
                    <InfiniteCalendar
                        minDate={today}
                        width={300}
                        height={300}
                        onSelect={(e) => dateChange(e)}
                    />
                    <Done onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>Done</Done>
                    </InnerWrapper>
                </Overlay>
            }
        </Wrapper>
    )
};

export default DateTimePicker;