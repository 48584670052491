import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SingleLoading from '../singleLoading';

const Wrapper = styled.div`
    
`
const EventWrapper = styled(Link)`
    padding: 10px;
    background: #121212;
    box-sizing: border-box;
    margin: 5px auto;
    width: 90%;
    max-width: 350px;
    text-decoration: none;
    color: #fff;
    display: block;
    &:hover {
        background: #000;
    }
    animation-delay: ${props => `${props.delay}ms`};
`
const Title = styled.div`
    padding: 5px;
    box-sizing: border-box;
`
const Elem = styled.div`
    color: #ddd;
    font-size: 0.8em;
    padding: 3px;
    box-sizing: border-box;
`

const SingleEvent = ({ uid, toggleDashboard, delay }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [eventData, setEventData] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        firebase.database().ref(`events/${uid}`).once('value')
        .then(snap => {
            setEventData(snap.val());
            setIsLoading(false);
            console.log(snap.val());
        })
        .catch(e => setError(e.msg));
    },[setIsLoading, uid]);

    return (
        <Wrapper>
            { isLoading ? 
                <SingleLoading /> :
                <EventWrapper to={`/e/${uid}`} onClick={toggleDashboard} delay={delay} className="animated fadeInRight">
                    <Title>{eventData.title}</Title>
                    <Elem>{moment(eventData.timestamp).format('dddd,\xa0 DD. MMM ´YY HH:mm')}</Elem>
                    <Elem>{ eventData.guests !== undefined ? 
                       `Responses: ${Object.keys(eventData.guests).length}` :
                       'No responses, yet.'
                    }</Elem>
                    { error !== '' ? <p>{error}</p> : null}
                </EventWrapper>
            }
        </Wrapper>
    )
};

export default SingleEvent;