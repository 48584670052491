import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Cancel, Save } from 'styled-icons/material';
import SingleLoading from '../singleLoading';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    z-index: 9999;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const TextArea = styled.textarea`
  color: #fff;
  width: 90%;
  max-width: 400px;
  margin: 10px;
  background: #111;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  display: block;
  min-height: 300px;
  font-size: 1em;
  font-family: 'arvo';
  border: 0;
`
const Btn = styled.div`
    cursor: pointer;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #A8BCB3;
    color: #222;
`
const BtnClose = styled(Btn)`
    background: #aaa;
`
const BtnWrap = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 200px;
    width: 90%;
`

const Edit = ({ text, toggleEdit, eventId, reloadEvent }) => {
    const [textVal, setTextVal] = useState(text);
    const [isSaving, setIsSaving] = useState(false);
    const onChange = (e) => {
        setTextVal(e.target.value);
    }
    const onSave = () => {
        setIsSaving(true);
        const updated = Date.now();
        const ref = firebase.database().ref(`events/${eventId}`);
        ref.update({ text: textVal, updated })
        .then(() =>{
            reloadEvent();
            toggleEdit();
            setIsSaving(false);   
        })
        .catch(e => setIsSaving(false));
        
    }
    return (
        <div>
            {   isSaving ?
                <Wrapper>
                <SingleLoading />
                </Wrapper>:
                <Wrapper className="animated fadeInUp">
                    <TextArea className="animated delay-1s fadeInUp" onChange={onChange} type="text" value={textVal} />
                    <BtnWrap>
                        <BtnClose onClick={toggleEdit}>
                            <Cancel color="#333" size={24} /> Cancel
                        </BtnClose>
                        <Btn onClick={onSave}>
                            <Save color="#333" size={24} /> Save
                        </Btn>
                    </BtnWrap>
                </Wrapper>
            }
        </div>
    );
}

export default Edit;