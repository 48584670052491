import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  background: rgba(0,0,0,0.5);
  box-sizing: border-box;
  max-width: 1000px;
`
const Input = styled.input`
  width: 100%;
  text-align: center;
  padding: 20px;
  background: none;
  border: 0;
  font-size: 20px;
  color: #fff;
  font-family: 'Arvo';
  box-sizing: border-box;
`

const EventImage = ({ title, changeTitle }) => (
  <Wrapper>
    <Input
      type="text"
      value={title}
      onChange={changeTitle}
      placeholder="Your event title."
    />
  </Wrapper>
)

export default EventImage;
