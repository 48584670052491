import React, { useState } from 'react';
import styled from 'styled-components';
import Label from './label';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
`
const Input = styled.textarea`
    background: none;
    border: 0;
    font-size: 2em;
    color: #fff;
    text-align: center;
    width: 90%;
    margin: auto;
    resize: none;
    overflow: hidden;
    min-height: 50px;
    max-height: 100px;
    &:focus {
        outline: none;
    }
`

const Index = ({ label, placeholder, id, nextStep, onValChange, event }) => {
    const [error, setError] = useState(false);

    const auto_grow = (element) => {
        element.target.style.height = "5px";
        element.target.style.height = (element.target.scrollHeight)+"px";
    }

    const onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if (event[id] === '') {
                setError(true);
                setTimeout(() => { setError(false); }, 1000);
            } else {
                nextStep();
            }
        }
    }
    return (
        <Wrapper className="animated fadeInUp slow">
            <label htmlFor="title">
                <Label>{ label }</Label>
            </label>
            <Input
                type="text"
                value={event[id]}
                onChange={(e) => onValChange(id, e.target.value)}
                placeholder={placeholder}
                id={id}
                multiline
                onInput={e => auto_grow(e)}
                autoFocus
                onSubmit={() => console.log('submitted')}
                onKeyDown={(e) => onEnterPress(e)}
                className={error && 'animated shake'}
            />
        </Wrapper>
    );
}

export default Index;