import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    background: #222;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: solid 2px #111;
    align-items: center;
    cursor: pointer;
    transition: all .3ms ease-in-out;
    &:hover {
        background: #111;
    }
`
const Label = styled.div`
    box-sizing: border-box;
    padding: 5px 20px;
`
const Value = styled.div`

`
const Image = styled.img`
    max-height: 70px;
    width: auto;
    display: block;
    margin: 0;
`

const ShowLine = ({ value, children, step, setStep, image }) => (
    <Wrapper onClick={() => setStep(step)} className="animated fadeInLeft">
        <Label>{children}</Label>
        { image ? 
            <Image src={value} /> :
            <Value>{value}</Value>
        }
    </Wrapper>
)

export default ShowLine;