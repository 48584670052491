import React from 'react';
import styled from 'styled-components';
import { SendPlane2 } from 'styled-icons/remix-line/';
import { Checkmark2 } from 'styled-icons/icomoon/';
import { Animated } from 'react-animated-css';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Success = styled(Animated)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Text = styled.p`
  color: #fff;
  background: rgba(0,0,0,0.5);
  padding: 20px;
  max-width: 300px;
  border-radius: 10px;
  box-sizing: border-box;
`

const Sending = ({ success, name, resp, hosts }) => (
  <Wrapper>
    { success ?
      <Success animationIn="fadeInDown" duration={2000}>
        <Checkmark2 size={40} color='#80f7bf' />
        { resp === 'going' ? <Text>Thank you {name} for your response. Awesome that you can make it! Keep an eye on this site as {hosts} can post updates.</Text> :
        <Text>Thank you {name} for your response. Too bad you can't come.</Text> }
      </Success> :
      <div className="animated fadeInLeft infinite">
        <SendPlane2 size={40} color='#80f7bf' />
      </div>
    }
  </Wrapper>
)

export default Sending;
