import React from 'react';
import styled from 'styled-components';
import CreateText from './createText';
import CreateDate from './createDate';
import CreateImage from './createImage';
import LastStep from './lastStep';

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s ease-in;
`
const CreateSteps = ({ setStep, currentStep, onValChange, event  }) => {
    const nextStep = (noCheck) => {
        const newStep = currentStep + 1; 
        setStep(newStep, noCheck);
    }

    return (
        <Wrapper>
            {   
                currentStep === 0 && <CreateText onValChange={onValChange} nextStep={nextStep} event={event} label="Title of your event:" placeholder="Gari event" id="title" />
            }
            {   
                currentStep === 1 && <CreateText onValChange={onValChange} nextStep={nextStep} event={event} label="Place of your event:" placeholder="Pizzeria X" id="place" />
            }
            {   
                currentStep === 2 && <CreateText onValChange={onValChange} nextStep={nextStep} event={event} label="Host of the event (Your name):" placeholder="Alex & Laura" id="host" />
            }
            {   
                currentStep === 3 && <CreateDate onValChange={onValChange} nextStep={nextStep} event={event} id="date" />
            }
            {   
                currentStep === 4 && <CreateImage onValChange={onValChange} nextStep={nextStep} event={event} id="image" />
            }
            {   
                currentStep === 5 && <LastStep onValChange={onValChange} nextStep={nextStep} event={event} id="text" />
            }
        </Wrapper>
    )
}

export default CreateSteps;