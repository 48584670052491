import React, { Component } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import Cookies from 'js-cookie'
import Sending from './sending';
import { Animated } from 'react-animated-css';
import { Check, Close, SendPlane2 } from 'styled-icons/remix-line/';

const Wrapper = styled(Animated)`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  padding: 70px 0 0 0;
  z-index: 9999;
  box-sizing: border-box;
`
const Button = styled.div`
  border-radius: 10px;
  padding: 7px;
  box-sizing: border-box;
  color: #333;
  background: ${props => props.dark ? '#aaa' : '#fff'};
  width: 120px;
  margin: auto;
  text-align: center;
  cursor: pointer;
`

const Label = styled.p`
  max-width: 400px;
  width: 90%;
  padding: 10px 30px;
  box-sizing: border-box;
  color: #fff;
  margin: 30px auto 10px auto;
`
const Input = styled.input`
  border-radius: 10px;
  background: rgba(250,250,250,0.7);
  padding: 10px;
  border: 0;
  font-size: 15px;
  color: #333;
  font-family: 'Arvo';
  margin: auto;
  box-sizing: border-box;
  width: 90%;
  max-width: 300px;
  margin: auto;
  display: block;
`
const SendBtn = styled.div`
  padding: 12px;
  box-sizing: border-box;
  font-size: 1.5em;
  color: #333;
  background: #A8BCB3;
  border-radius: 10px;
  margin: 20px auto 0 auto;
  display: block;
  width: 150px;
  text-align: center;
  cursor: pointer;
  transition: all .5s ease-in;
  &:hover {
    background: #80f7bf;
  }
`
const Hide = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const Error = styled.div`
  color: #f55;
  text-align: center;
  margin-top: 10px;
`

class SendResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responder: '',
      resp: '',
      error: '',
      sending: false,
      success: false
    }
  }

  componentDidMount() {
    this.setState({ resp: this.props.resp });
  }

  changeResponder = (e) => {
    const responder = e.target.value;
    this.setState({ responder });
  }

  toggleResp = () => {
    const r = this.state.resp;
    r === 'going' ? this.setState({ resp: 'cant' }) : this.setState({ resp: 'going' });
  }

  sendResp = () => {
    const name = this.state.responder;
    const k = this.props.eventKey;
    const resp = this.state.resp;
    const timestamp = Date.now();
    if (name === '') {
      this.setState({ error: 'Please enter a name.' });
    } else {
      this.setState({ sending: true });
      const ref = firebase.database().ref(`/events/${k}/guests`);
      const key = ref.push().key;
      Cookies.set(`${k}`, `${resp}`);
      Cookies.set('uid', `${key}`);
      this.props.cookieResp(resp);
      firebase.database().ref(`events/${k}/guests/${key}`).set({ name, timestamp, resp })
      .then(() => {
        setTimeout(() => {
          this.setState({ success: true });
        }, 500);
      })
      .catch(e => this.setState({ sending: false, error: e.message}));
    }
  }

  render() {
    return (
      <Wrapper animationIn="fadeInUp">
        <Hide onClick={() => this.props.hideResp()}><Close size={40} color="#fff" /></Hide>
        { this.state.sending ?
          <Sending name={this.state.responder} resp={this.state.resp} hosts={this.props.hosts} success={this.state.success} /> :
          <div>
            { this.state.resp === 'going' ?
              <Button onClick={() => this.toggleResp()}><Check size={24} color="#333" /> Going</Button> :
              <Button dark onClick={() => this.toggleResp()}><Close size={24} color="#333" /> Can't go</Button>
            }
            <Label>
              Tell {this.props.hosts} who you are:
            </Label>
            <Input
              type="text"
              value={this.state.responder}
              onChange={this.changeResponder}
              placeholder="Your name..."
              autoFocus
            />
            <SendBtn onClick={() => this.sendResp()}>
              Send <SendPlane2 size={24} color="#333" />
            </SendBtn>
            { this.state.error !== '' && <Error>{this.state.error}</Error> }
          </div>
        }
      </Wrapper>
    );
  }
}

export default SendResponse;
