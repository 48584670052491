import React, { useState } from 'react';
import styled from 'styled-components';
import { UserDetail } from 'styled-icons/boxicons-solid';
import { Close } from 'styled-icons/remix-line/';
import Dashboard from './dashboard';

const Wrapper = styled.div`
    
`
const Button = styled.div`
    position: fixed;
    cursor: pointer;
    top: 25px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #212121;
    background: #eee;
    text-decoration: none;
    border-radius: 25px;
    background: rgba(0,0,0,0.8);
    z-index: 9998;
    &:hover {
        background: #000;
    }
`

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDashboard = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
    }
    
    return (
        <Wrapper>
            <Button className="animated slow fadeInRight" onClick={() => toggleDashboard()}>
                { isOpen ?
                <Close size={24} color="#fff" /> :
                <UserDetail size={24} color="#fff" />
                }
            </Button>
            {
                isOpen && <Dashboard toggleDashboard={toggleDashboard} />
            }
        </Wrapper>
    );
};

export default Header;