import React, { useState } from 'react';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { User, MapPin2, Information, Time, Pencil, RoadMap } from 'styled-icons/remix-line/';
import Edit from './edit';
import EditPlace from './editPlace';

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 0 30px 0;
`
const Image = styled.img`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: block;
  margin: 20px auto 0 auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
const DateTitle = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 0 auto 20px auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #eee;
  display: flex;
  align-items: center;
  ${props => props.hasImage ? null :  `
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 20px;
  `}
`
const Date = styled.div`
  width: 50px;
  padding: 10px;
`
const Month = styled.div`
  background: #f55;
  text-align: center;
  text-transform: uppercase;
  padding: 2px;
`
const Day = styled.div`
  color: #333;
  text-align: center;
`
const DayName = styled.div`
  font-size: 0.7em;
  color: #f55;
  text-align: center;
`

const Title = styled.div`
  flex: 1;
`
const TitleH = styled.h1`
  color: #333;
  font-size: 1.2em;
`
const Wrap = styled.div`
  width: 90%;
  padding: 10px 0 10px 0;
  box-sizing: border-box;
  max-width: 700px;
  margin: auto;
`
const WrapInner = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const I = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
`
const Icons = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  flex-direction: column;
`

const A = styled.a`
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  background: #222;
  border-radius: 7px;
`

const EditIcon = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #ddd;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const P = styled.p`
  color: #fff;
  flex: 1;
  margin: 0;
  background: rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  display: block;
`
const TextArea = styled(TextareaAutosize)`
  color: #fff;
  flex: 1;
  margin: 0;
  background: rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  display: block;
  min-height: 300px;
  font-size: 1em;
  font-family: 'arvo';
  border: 0;
`
const Updated = styled.p`
  display: block;
  color: #fff;
  padding: 10px 10px 10px 50px;
  margin: 0;
`
const Calendar = styled.div`
  font-size: 0.7em;
`

const EventInfo = ({ reload, eventId, hasImage, hosts, place, text, timestamp, title, imgSrc, admin, updated, lat, lon }) => {

  const [edit, setEdit] = useState('');
  
  const toggleEdit = (type) => {
    edit === '' ? setEdit(type) : setEdit('');
  };

  const reloadEvent = () => {
    reload();
  }

  const event = {
    title,
    description: text,
    location: place,
    startTime: moment(timestamp).toISOString(),
    endTime: moment(timestamp).toISOString()
  }

  let icon = { 'calendar-plus-o': 'left' };
  
  return (
    <Wrapper>
      { edit === 'information' && <Edit eventId={eventId} reloadEvent={reloadEvent} text={text} toggleEdit={toggleEdit} />}
      { edit === 'place' && <EditPlace eventId={eventId} reloadEvent={reloadEvent} place={place} toggleEdit={toggleEdit} />}
      { hasImage && <Image src={imgSrc} />}
      <DateTitle hasImage={hasImage}>
        <Date>
          <Month>{moment(timestamp).format('MMM')}</Month>
          <Day>{moment(timestamp).format('DD')}</Day>
          <DayName>{moment(timestamp).format('dddd')}</DayName>
        </Date>
        <Title>
          <TitleH>{title}</TitleH>
        </Title>
      </DateTitle>
      <Wrap>
          <WrapInner>
          <I><User size={20} color="#fff" /></I>
          <P>{hosts}</P>
        </WrapInner>
      </Wrap>
      <Wrap>
          <WrapInner>
          <I><Time size={20} color="#fff" /></I>
          <P>{moment(timestamp).format('dddd,\xa0 DD. MMM ´YY HH:mm')}</P>
        </WrapInner>
      </Wrap>
      <Wrap>
          <WrapInner>
          <Icons>
            <I><MapPin2 size={20} color="#fff" /></I>
            { admin && <EditIcon onClick={() => toggleEdit('place')}>
              <Pencil size={18} color="#333" />
            </EditIcon> }
          </Icons>
          <P>{place}</P>
          { typeof lat !== 'undefined' && 
            <A target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}>
              <RoadMap size={22} color="#fff" /> 
            </A>
          }
        </WrapInner>
      </Wrap>
      <Wrap>
          <WrapInner>
            <Icons>
              <I><Information size={20} color="#fff" /></I>
              { admin && <EditIcon onClick={() => toggleEdit('information')}>
                <Pencil size={18} color="#333" />
              </EditIcon> }
            </Icons>
          <TextArea disabled value={text} />
        </WrapInner>
        { updated && <Updated>updated {moment(updated).fromNow()}</Updated> }
      </Wrap>
      <Wrap>
        <WrapInner>
          <Calendar>
            <AddToCalendar event={event} buttonTemplate={icon} />
            [Beta]
          </Calendar>
        </WrapInner>
      </Wrap>
    </Wrapper>
  )
};

export default EventInfo;
