import React, { Component } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import { Animated } from 'react-animated-css';
import EventPreview from './eventPreview';
import ShareLink from './shareLink';
import ShareButtons from './shareButtons';
import Loading from '../loading';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 30px;
  box-sizing: border-box;
`
const CopySuccess = styled(Animated)`
  background: rgba(255,255,255,0.8);
  color: #333;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  position: fixed;
  bottom: 30px;
  left: 50%;
  margin-left: -90px;
  margin-top: -21px;
  width: 180px;
  text-align: center;
`

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hasImage: false,
      hosts: '',
      place: '',
      text: '',
      timestamp: 0,
      title: '',
      imgSrc: '',
      copySuccess: false
    }
  }
  componentDidMount() {
    this.getEvent();
  }

  getEvent = () => {
    const k = this.props.match.params.eventKey;
    const ref = firebase.database().ref(`events/${k}`);
    ref.once('value').then(snap => {
      console.log(snap.val());
      const { hasImage, hosts, place, text, timestamp, title } = snap.val();
      this.setState({ hasImage, hosts, place, text, timestamp, title });
      if (hasImage) {
        const ref = firebase.storage().ref(`/events/${k}/cover`);
        ref.getDownloadURL().then(imgSrc => {
          this.setState({ loading: false, imgSrc });
          console.log(imgSrc);
        })
        .catch(e => console.log(e, 'error when fetching image'));
      } else {
        this.setState({ loading: false });
      }
    })
    .catch(e => console.log(e));
  }

  copySuccess = () => {
    this.setState({ copySuccess: true });
    setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 2000);
  }

  render() {
    const { eventKey } = this.props.match.params;
    const { hasImage, hosts, place, text, timestamp, title, imgSrc } = this.state;
    return (
      <Wrapper>
        { this.state.copySuccess && <CopySuccess animationIn="flipInX">Copied to clipboard</CopySuccess> }
        { this.state.loading ? <Loading /> :
        <EventPreview
          hasImage={hasImage}
          hosts={hosts}
          place={place}
          text={text}
          timestamp={timestamp}
          title={title}
          imgSrc={imgSrc}
        />
        }
        <ShareLink copySuccess={this.copySuccess} eventKey={eventKey} />
        <ShareButtons
          shareUrl={`${window.location.host}/e/${eventKey}`}
          inviteText={`I want to invite you to: ${title}`}
          title={title}
        />
      </Wrapper>
    );
  }
}

export default Share;
