import React from 'react';
import styled from 'styled-components';
import Guests from './guests';
import { Animated } from 'react-animated-css';
import { Close } from 'styled-icons/remix-line/';

const Wrapper = styled(Animated)`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.9);
  top: 0;
  left: 0;
  padding: 70px 0 0 0;
  box-sizing: border-box;
  z-index: 9999;
  overflow-y: auto;
  padding-bottom: 20px;
`

const Hide = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const Stats = styled.div`
  width: 90%;
  margin: auto;
  background: #222;
  color: #fff;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
`

const Admin = ({ guests, hideResp }) => {
    let going = 0;
    let cant = 0;
    if (guests) {
      Object.keys(guests).forEach(key => {
        guests[key].resp === 'going' ? going++ : cant++;
      })
    }
    return (
      <Wrapper animationIn="fadeInUp">
        <Hide onClick={() => hideResp()}><Close size={40} color="#fff" /></Hide>
        <Guests guests={guests} />
        <Stats>Going: {going} | Can't: {cant}</Stats>
      </Wrapper>
    )
}

export default Admin;
