import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';
import 'firebase/auth';
import Loading from './loading';

const Wrapper = styled.section`
`
const Heading = styled.h1`
    cursor: pointer;
    font-size: 0.8em;
    padding: 10px;
    margin: 0;
    box-sizing: border-box;
    font-weight: 100;
    transition: all .3s ease-in-out;
    ${props => props.active && `
        font-weight: bold;
        font-size: 1em;
    `}
    &:hover {
        font-weight: bold;
        font-size: 1em;
    }
`
const HeadWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
const Input = styled.input`
    background: #222;
    padding: 7px;
    border: 0;
    border-radius: 7px;
    display: block;
    margin: 5px auto;
    color: #fff;
`
const FormWrap = styled.div`

`
const SubmitBtn = styled.div`
    cursor: pointer;
    background: #ddd;
    padding: 7px;
    display: inline-block;
    color: #333;
    margin-top: 10px;
    border-radius: 7px;
    transition: all .3s ease-in-out;
    &:hover {
        background: #fff;
    }
`
const Error = styled.p`
    color: #f00;
`
const Logout = styled.div`
`

const LoginSignup = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [signUpLogin, setSignUpLogin] = useState('signup');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPw, setConfirmPw] = useState('');
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setIsLoggedIn(!user.isAnonymous);
            }
        });
    }, [setIsLoggedIn]);

    const onSubmit = () => {
        if (signUpLogin === 'signup') {
            if (password !== confirmPw) {
                setError('Failed password confirmation.');
            } else {
                setError('');
                setIsLoading(true);
                // SignUp this motherfucker!
                console.log(email, password);
                var credential = firebase.auth.EmailAuthProvider.credential(email, password);
                console.log(credential);
                firebase.auth().currentUser.linkWithCredential(credential)
                .then(function(usercred) {
                    setIsLoggedIn(true);
                    setIsLoading(false);
                }).catch(function(error) {
                    setError(error.message);
                    setIsLoading(false);
                });
            }
        } else if (signUpLogin === 'login') {
            firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
                // Handle Errors here.
                setError(error.message);
                // ...
            });
        }
    }

    const logout = () => {
        firebase.auth().signOut().then(function() {
            // Sign-out successful.
            setIsLoggedIn(false);
        }).catch(function(error) {
          // An error happened.
          setError(error.message);
        });
    }

    return (
        <Wrapper className="animated fadeInUp delay-2s slow">
            { isLoading && <Loading /> }
            { isLoggedIn ?
            <Logout onClick={() => logout()}><SubmitBtn>Logout</SubmitBtn></Logout> 
            :<div>
                <HeadWrap>
                    <Heading onClick={() => setSignUpLogin('signup')} active={signUpLogin === 'signup'}>Sign Up</Heading>
                    <Heading onClick={() => setSignUpLogin('login')} active={signUpLogin === 'login'}>Login</Heading>
                </HeadWrap>
                <FormWrap>
                    <Input placeholder="E-Mail" type="text" value={email} onChange={e => setEmail(e.target.value)} />
                    <Input placeholder="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    { signUpLogin === 'signup' && 
                    <Input placeholder="Password confirm" type="password" value={confirmPw} onChange={e => setConfirmPw(e.target.value)} /> 
                    }
                </FormWrap>
                { error !== '' && <Error>{error}</Error> }
                <SubmitBtn onClick={() => onSubmit()}>
                    { signUpLogin === 'signup' ? 'Sign Up' : 'Login' }
                </SubmitBtn>
            </div>
            }
        </Wrapper>
    )
};

export default LoginSignup;