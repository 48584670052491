import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from './../assets/logo.png';

const Wrapper = styled.footer`
    width: 100%;
    height: 69px;
    background: #222;
    border-top: 1px #000 solid;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
`
const Logo = styled.img`
    width: 40px;
    height: auto;
`

const A = styled.a`
    text-decoration: none;
    color: #fff;
`
const SLink = styled(Link)`
    font-size: 0.7em;
    color: #fff;
    text-decoration: none;
    &:hover {
        color: #ddd;
    }
`

const Footer = () => (
    <Wrapper>
        <Link to="/"><Logo src={logo} /></Link>
        <SLink to="/privacy">Impressum / Datenschutz</SLink>
        <A href="https://kiefer.media" target="_blank">by KIEFER.MEDIA</A>
    </Wrapper>
);

export default Footer;