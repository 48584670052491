import React from 'react';
import styled from 'styled-components';
import Privacy from './privacy';

const Wrapper = styled.div`
    overflow: auto;
    flex: 1;
    box-sizing: border-box;
`

const Index = () => {
    return (
        <Wrapper>
            <Privacy />
        </Wrapper>
    )
};

export default Index;