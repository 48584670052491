import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import styled from 'styled-components';
import NoEvents from './noEvents';
import SingleEvent from './singleEvent';
import Loading from '../loading';
import LoginSignup from '../loginSignup';

const Wrapper = styled.div`
    
`
const EventsWrapper = styled.div``

const P = styled.p`
    max-width: 420px;
    padding: 20px;
    margin: auto;
    box-sizing: border-box;
`

const AllEvents = ({ toggleDashboard }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const uid = user.uid;
                setShowLogin(user.isAnonymous);
                firebase.database().ref(`user/${uid}`).once('value').then(snap => {
                    const eventArr = [];
                    const events = snap.val();
                    Object.keys(snap.val()).forEach(key => {
                        eventArr.push(events[key].eventId);
                    })
                    setEvents(eventArr.reverse());
                    setIsLoading(false);
                })
            } else {
                console.log('no User');
                setShowLogin(true);
                setIsLoading(false);
            }
        });
    }, [setEvents]);
    let i = 400;
    return (
        <Wrapper>
            { isLoading ? <Loading /> :
                events === null ?
                    <NoEvents toggleDashboard={toggleDashboard} /> :
                    <EventsWrapper>
                        {
                            events.map(key => {
                                // when I was setting this up I was stupid.
                                // eventId is not the key
                                i += 200;
                                return <SingleEvent toggleDashboard={toggleDashboard} delay={i} uid={key} key={key} />
                            })
                        }
                        { showLogin &&
                            <P className="animated fadeInUp slow delay-2s">
                                You are not logged in. If you want to save your events permanently and access it on other devices (optional):
                            </P>
                        }
                        <LoginSignup />
                    </EventsWrapper>
            }
        </Wrapper>
    );
};

export default AllEvents;