import React, { Component } from 'react';
import styled from 'styled-components';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { Upload2 } from 'styled-icons/remix-fill/';
import { Checkmark2 } from 'styled-icons/icomoon/';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Status = styled.p`
  color: #fff;
  font-family: 'arvo';
  font-size: 1.5em;
`
const Success = styled(Animated)`

`
class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      status: 'Creating your event.',
      eventKey: ''
    }
  }
  componentDidMount() {
    this.validate();
  }

  validate = () => {
    const title = this.props.title;
    const place = this.props.place;
    const hosts = this.props.hosts;
    const text = this.props.text;
    const timestamp = this.props.timestamp;
    const image = this.props.image;
    const tmr = new Date();
    tmr.setDate(tmr.getDate());
    if (title === '' || place === '' || hosts === '' || text === '') {
      this.props.error('You have to fill out all the fields. Come on that is not a lot :)');
    } else if (timestamp < tmr.getTime()) {
      this.props.error('The date should be in the future. Give your guests time.');
    } else {
      this.createEvent(title, place, hosts, text, timestamp, image);
    }
  }

  createEvent = (title, place, hosts, text, timestamp, image) => {
    let hasImage = false;
    image === '' ? hasImage = false : hasImage = true;
    const key = firebase.database().ref('/events').push().key;
    const ref = firebase.database().ref(`events/${key}`);
    ref.set({ title, place, hosts, text, timestamp, hasImage })
    .then(() => {
      if (hasImage) {
        firebase.storage().ref(`events/${key}/cover`).putString(image.substring(23), 'base64', {contentType: 'image/jpg'})
        .then(test => this.success(key))
        .catch(() => this.props.error('There was an error while saving your Event. Try again or contact me at alex@kiefer.media'));
      } else {
        this.success(key)
      }
    })
    .catch(() => this.props.error('There was an error while saving your Event. Try again or contact me at alex@kiefer.media'));
  }

  success = (key) => {
    firebase.auth().signInAnonymously()
    .then(user => {
      const uid = user.user.uid;
      firebase.database().ref(`/user/${uid}/`).push({ eventId: key })
      .then(() => {
        this.showSuccess(key);
      })
    })
    .catch(error => {
      console.log(error);
    });
  }

  showSuccess = (key) => {
    setTimeout(() => {
      this.setState({ loading: false, status: 'Saving successful.', eventKey: key });
    }, 500);

    setTimeout(() => {
      this.setState({ redirect: true });
    }, 1200);
  }

  render() {
    return (
      <Wrapper>
        { this.state.loading ?
          <div className="animated fadeInUp infinite">
            <Upload2 size={40} color="#fff" />
          </div> :
          <Success animationIn="fadeInDown"><Checkmark2 size={40} color='#fff' /></Success>
        }
        { this.state.redirect && <Redirect to={`share/${this.state.eventKey}`} /> }
        <Status>{this.state.status}</Status>
      </Wrapper>
    );
  }
}

export default CreateEvent;
