import React from 'react';
import styled from 'styled-components';
import placeholder from '../../assets/placeholder1.jpg';


const Wrapper = styled.div`
  width: 100%;
`
const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 500px;
  display: block;
  cursor: pointer;
  margin: auto;
`
const Input = styled.input`
  display: none;
`
const Optional = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0,0,0,0.8);
  padding: 5px;
  box-sizing: border-box;
  font-size: 0.8em;
  cursor: pointer;
`

const EventImage = ({ setImage, image }) => (
  <Wrapper>
    <label htmlFor="file-input">
      <Optional>Image optional</Optional>
      { image !== '' ?
        <Image src={image} alt="Event Image" /> :
        <Image src={placeholder} alt="Event Image" />
      }
    </label>
    <Input type="file" id="file-input" onChange={setImage} name="file-input" visibility="hidden" />
  </Wrapper>
)

export default EventImage;
