import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 250px;
  margin-top: 20px;
`
const I = styled.input`
  padding: 20px;
  height: 50px;
  width: 50px;
  font-size: 24px;
  color: #fff;
  background: rgba(250,250,250,0.1);
  text-align: center;
  border: 0;
  border-radius: 45px;
`
const Dots = styled.div`
  font-size: 30px;
  font-family: 'Arvo';
`

const handleFocus = (event) => event.target.select();

const TimeSelect = ({ sHour, sMinute, hours, minutes }) => {
  console.log(hours, minutes);
  return (
  <Wrapper>
    <I type="number" onChange={sHour} value={hours} placeholder="18" onFocus={handleFocus} />
    <Dots>:</Dots>
    <I type="number" onChange={sMinute} value={minutes} placeholder="30" onFocus={handleFocus} />
  </Wrapper>
)
}

export default TimeSelect;
