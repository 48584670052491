import React, { Component } from 'react';
import styled from 'styled-components';
import InfiniteCalendar from 'react-infinite-calendar';
import { Checkmark2 } from 'styled-icons/icomoon/';
import TimeSelect from './timeSelect';
import 'react-infinite-calendar/styles.css';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 99999;
  flex-grow: 1;
`
const Button = styled.div`
  cursor: pointer;
  background: #aaa;
  padding: 10px;
  margin: 10px auto;
  color: #333;
  border-radius: 10px;
`

const today = new Date();

class SelectDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      hours: null,
      minutes: null
    }
  }

  componentDidMount() {
    const t = this.props.timestamp;
    if (t !== 0) {
      const date = new Date(t);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      console.log(hours, minutes);
      this.setState({ date, hours, minutes });
    } else {
      const date = new Date();
      this.setState({ date });
    }
  }

  dateChange = (date) => {
    const h = this.state.hours;
    const m = this.state.minutes;
    date.setHours(h);
    date.setMinutes(m);
    this.setState({ date });
    this.props.setTimestamp(date.getTime());
  }

  sHour = (h) => {
    const date = this.state.date;
    const hours = h.target.value;
    if (hours < 25 && hours >= 0) {
      date.setHours(hours);
      this.setState({ date, hours });
      this.props.setTimestamp(date.getTime());
    }
  }

  sMinute = (m) => {
    const date = this.state.date;
    const minutes = m.target.value;
    if (minutes < 60 && minutes >= 0) {
      date.setMinutes(minutes);
      this.setState({ date, minutes });
      this.props.setTimestamp(date.getTime());
    }
  }

  toggle = (e) => {
    e.preventDefault();
    if(e.target === e.currentTarget) {
      this.props.toggleDate();
    }
  }

  render() {
    return (
      <Wrapper onClick={this.toggle}>
        <InfiniteCalendar
          selected={this.state.date}
          minDate={today}
          width={300}
          height={300}
          onSelect={(e) => this.dateChange(e)}
        />
        <TimeSelect hours={this.state.hours} minutes={this.state.minutes} sHour={this.sHour} sMinute={this.sMinute} />
        <Button onClick={this.toggle}><Checkmark2 color="#333" size={24} /> Done</Button>
      </Wrapper>
    );
  }
}

export default SelectDateTime;
