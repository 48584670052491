import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ShowLine from './showLine';
import { User, MapPin2, Time, Image } from 'styled-icons/remix-line/';

const Wrapper = styled.div`
    width: 100%;
`
const Title = styled.div`
    text-align: center;
    font-size: 1.5em;
    padding: 10px;
    box-sizing: border-box;
    background: #222; 
    cursor: pointer;
    transition: all .3ms ease-in-out;
    &:hover {
        background: #111;
    }
`

const ShowEvent = ({ event, setStep }) => {
    console.log(event);
    if (Object.keys(event).length === 0 && event.constructor === Object) {
        return null;
    } else {
        return (
            <Wrapper>
                { event.title !== undefined && <Title onClick={() => setStep(0)}>{event.title}</Title> }
                { event.place !== undefined && 
                    <ShowLine setStep={setStep} step={1} value={event.place}><MapPin2 size={35} color="#fff" /></ShowLine> 
                }
                { event.host !== undefined && 
                    <ShowLine setStep={setStep} step={2} value={event.host}><User size={35} color="#fff" /></ShowLine> 
                }
                { event.date !== undefined &&
                    <ShowLine setStep={setStep} step={3} value={moment(event.date).format('dddd,\xa0 DD. MMM ´YY HH:mm')}><Time size={35} color="#fff" /></ShowLine>
                }
                { event.image !== undefined &&
                    <ShowLine setStep={setStep} step={4} image value={event.image}><Image size={35} color="#fff" /></ShowLine>
                }
            </Wrapper>
        )
    }
}

export default ShowEvent;