import React from 'react';
import styled from 'styled-components';
import { Check, Close, FileList } from 'styled-icons/remix-line/';

const Wrapper = styled.div`
  height: 60px;
  width: 100%;
  background: #222;
`
const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 400px;
  width: 100%;
  height: 60px;
  margin: auto;
`
const Button = styled.div`
  cursor: pointer;
  border-radius: 10px;
  padding: 7px;
  box-sizing: border-box;
  color: #333;
  background: ${props => props.dark ? '#aaa' : '#fff'};
  background: ${props => props.showColor && '#A8BCB3' };
`


const Response = ({ respond, respCookie, admin }) => {
  if (admin) {
    return (
      <Wrapper>
        <InnerWrapper>
          <Button showColor onClick={() => respond('admin')}><FileList size={24} color="#333" /> See responses</Button>
        </InnerWrapper>
      </Wrapper>
    )
  }

  if (respCookie !== '') {
    return (
      <Wrapper>
        <InnerWrapper>
          { respCookie === 'going' ?
          <Button onClick={() => respond('decided')}><Check size={24} color="#333" /> Going</Button> :
          <Button dark onClick={() => respond('decided')}><Close size={24} color="#333" /> Can't go</Button>
          }
        </InnerWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <InnerWrapper>
          <Button onClick={() => respond('going')}><Check size={24} color="#333" /> Going</Button>
          <Button dark onClick={() => respond('cant')}><Close size={24} color="#333" /> Can't go</Button>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Response;
