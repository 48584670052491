import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Check, Close } from 'styled-icons/remix-line/';

const Line = styled.div`
  width: 90%;
  margin: auto;
  background: #333;
  padding: 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
  color: #eee;
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  width: 50px;
`
const NameTime = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Name = styled.div`
  font-size: 1.2em;
`
const Time = styled.div`
  font-size: 0.8em;
`

const Guests = ({ guests, hideResp }) => {
  const guestList = [];
  if (guests) {
    Object.keys(guests).forEach(key => {
      guestList.push(
        <Line>
          <Icon>
            { guests[key].resp === 'going' ?
              <Check size={30} color="#80f7bf" /> :
              <Close size={30} color="#b21a1a" />
            }
          </Icon>
          <NameTime>
            <Name>
              {guests[key].name}
            </Name>
            <Time>
              {moment(guests[key].timestamp).fromNow()}
            </Time>
          </NameTime>
        </Line>
      );
    })
  }
  return guestList;
}

export default Guests;
