import React, { useState } from 'react';
import styled from 'styled-components';
import { AddCircle } from 'styled-icons/material';
import Label from './label';
import SaveToDB from './saveToDB';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: block;
    flex: 1;
`
const Input = styled.textarea`
  padding: 20px;
  box-sizing: border-box;
  background: none;
  border: 0;
  font-size: 15px;
  color: #fff;
  font-family: 'Arvo';
  background: rgba(0,0,0,0.5);
  margin: 20px auto;
  border-radius: 10px;
  height: 230px;
  width: 100%;
`
const TextWrapper = styled.div`
    width: 100%;
    min-width: 300px;
    display: block;
    flex: 1;
    text-align: center;
`
const Submit = styled.div`
    background: #fff;
    padding: 10px;
    display: inline-block;
    color: #333;
    border-radius: 10px;
    margin: 10px auto;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
        background: #ddd;
    }
`

const LastStep = ({ onValChange, id, event }) => {

    const [submit, setSubmit] = useState(false);
    
    const onSubmitEvent = () => {
        setSubmit(true);
    }
    
    return (
        <Wrapper>
            { submit && <SaveToDB event={event} /> }
            <Label>Last step:</Label>
            <TextWrapper>
                <Input
                    type="text"
                    onChange={(e) => onValChange(id, e.target.value)}
                    placeholder="Give your guests some closer information. (You can still edit this later)"
                    value={event[id]}
                />
                { event[id] !== undefined && event[id] !== '' && 
                    <Submit onClick={onSubmitEvent} className="animated slow fadeInUp"><AddCircle size={30} color="#333" /> Create Event</Submit> 
                }
            </TextWrapper>
        </Wrapper>
    )
}

export default LastStep;