import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import firebase from 'firebase';
import { Redirect } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import { Upload2 } from 'styled-icons/remix-fill/';
import { Checkmark2 } from 'styled-icons/icomoon/';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Status = styled.p`
  color: #fff;
  font-family: 'arvo';
  font-size: 1.5em;
`
const Success = styled(Animated)`

`
const SaveToDB = ({ event }) => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [status, setStatus] = useState('Creating your event.');
  const [eventKey, setEventKey] = useState('');

    useEffect(() => {
      const createEvent = () => {
        const { title, place, host, text, date, image } = event; 
        const hosts = host;
        const timestamp = date;
        let hasImage = true;
        if (image === undefined) {
            hasImage = false;
        }
        const key = firebase.database().ref('/events').push().key;
        const ref = firebase.database().ref(`events/${key}`);
        ref.set({ title, place, hosts, text, timestamp, hasImage })
        .then(() => {
        if (hasImage) {
            firebase.storage().ref(`events/${key}/cover`).putString(image.substring(23), 'base64', {contentType: 'image/jpg'})
            .then(test => success(key))
            .catch(() => setStatus('There was an error while saving your Event. Try again or contact me at alex@kiefer.media'));
        } else {
            success(key)
        }
        })
        .catch(() => setStatus('There was an error while saving your Event. Try again or contact me at alex@kiefer.media'));
      }

      const success = (key) => {
        firebase.auth().signInAnonymously()
        .then(user => {
        const uid = user.user.uid;
        firebase.database().ref(`/user/${uid}/`).push({ eventId: key })
        .then(() => {
            showSuccess(key);
        })
        })
        .catch(error => {
        console.log(error);
        });
      }

      const showSuccess = (key) => {
          setTimeout(() => {
              setStatus('Saving successful.');
              setLoading(false);
              setEventKey(key);
          }, 500);

          setTimeout(() => {
              setRedirect(true);
          }, 1200);
      }
      createEvent();
    }, [event]);

    return (
      <Wrapper>
        { loading ?
          <div className="animated fadeInUp infinite">
            <Upload2 size={40} color="#fff" />
          </div> :
          <Success animationIn="fadeInDown"><Checkmark2 size={40} color='#fff' /></Success>
        }
        { redirect && <Redirect to={`share/${eventKey}`} /> }
        <Status>{status}</Status>
      </Wrapper>
    );
}

export default SaveToDB;
