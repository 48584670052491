import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    box-sizing: border-box;
    flex-direction: column;
    max-width: 470px;
    margin: auto;
`
const H = styled.h1`

`
const P = styled.p`

`

const WhatIs = () => {
    return (
        <Wrapper>
            <H>What is it?</H>
            <P>With INWE.IT you can invite people to your party or event. You and your invitees never need to sign up. Just go
                ahead and create your first event. 
            </P>
        </Wrapper>
    )
};

export default WhatIs;