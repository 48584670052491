import React from 'react';
import styled from 'styled-components';
import {
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

const Wrapper = styled.div`
  max-width: 400px;
  padding: 0 20px 7px 20px;
  box-sizing: border-box;
  margin: auto;
`
const IconWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const I = styled.div`
  padding: 5px;
  box-sizing: border-box;
`

const Text = styled.p`
  margin: 0 0 10px 0;
`

const ShareButtons = ({ shareUrl, title, inviteText }) => (
  <Wrapper>
    <Text>Or just use one of these buttons:</Text>
    <IconWrapper>
      <I>
        <WhatsappShareButton
          url={shareUrl}
          title={inviteText}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </I>
      <I>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body={`${inviteText} use this link: ${shareUrl}`}
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </I>
      <I>
        <FacebookShareButton
          url={shareUrl}
          quote={`${inviteText} use this link: ${shareUrl}`}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </I>
      <I>
        <TelegramShareButton
          url={shareUrl}
          title={inviteText}
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </I>
    </IconWrapper>
  </Wrapper>
);

export default ShareButtons;
