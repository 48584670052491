import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Resizer from 'react-image-file-resizer';
import placeholder from '../../assets/placeholder1.jpg';

const Wrapper = styled.div`
    
    text-align: center;
`
const Image = styled.img`
    width: 90%;
    display: block;
    max-width: 300px;
    height: auto;
    margin: auto;
`

const Input = styled.input`
  display: none;
`
const P = styled.p`

`
const Label = styled.label`
    cursor: pointer;
    text-align: center;
`
const Done = styled.div`
    border-radius: 15px;
    background: #ddd;
    padding: 10px;
    color: #333;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    margin: 10px auto;
`

const AddImage = ({ onValChange, nextStep, event }) => {

    useEffect(() => {
        if (event.image !== undefined) {
            setImage(event.image);
        }
    }, [event])
    
    const [image, setImage] = useState('');

    const onSetImage = (e) => {
        console.log('setImage0,', e.target.value);
        const i = e.target.files[0];
        Resizer.imageFileResizer(i, 1000, 500, 'JPEG', 100, 0, image => {
            setImage(image);
            onValChange('image', image);
            nextStep(true);
        });
    }

    return (
        <Wrapper className="animated fadeInUp slow">
            <Label htmlFor="file-input">
                <P>(optional) add Image</P>
            { image !== '' ?
                <Image src={image} alt="Event Image" /> :
                <Image src={placeholder} alt="Event Image" />
            }
            </Label>
            <Done onClick={() => nextStep(true)}>Skip</Done>
            <Input type="file" id="file-input" onChange={onSetImage} name="file-input" visibility="hidden" />
        </Wrapper>
    );
};

export default AddImage;