import React from 'react';
import styled, { keyframes } from "styled-components";
import { Spinner2 } from 'styled-icons/evil/';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  padding: 10px;
  box-sizing: border-box;
`;


const SingleLoading = () => (
    <Spinner>
        <Spinner2 size={30} color="#fff" />
    </Spinner>
)

export default SingleLoading;