import React, { Component } from 'react';
import styled from 'styled-components';
import { AddCircle } from 'styled-icons/material';
import { Animated } from 'react-animated-css';
import Resizer from 'react-image-file-resizer';
import EventImage from './eventImage';
import EventTitle from './eventTitle';
import EventHosts from './eventHosts';
import EventPlace from './eventPlace';
import EventText from './eventText';
import EventDate from './eventDate';
import CreateEvent from './createEvent';
import SelectDateTime from './selectDateTime';
import Error from './error';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
const ButtonWrapper = styled(Animated)`
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0,0,0,0.2);
`

const Button = styled.div`
  color: #fff;
  cursor: pointer;
  background: rgba(0,0,0,0.5);
  border-radius: 60px;
  padding: 10px;
  width: 110px;
  margin: auto;
  text-decoration: none;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &:hover {
    background: rgba(0,0,0,0.8);
  }
`
const Content = styled(Animated)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`
// when I first created this app I messed styling up a bit.
// Sorry for that:
const CInner = styled.div`
  max-width: 700px;
  margin: auto;
`

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      timestamp: 0,
      showDate: false,
      text: '',
      image: '',
      hosts: '',
      creating: false,
      errorMsg: '',
      place: ''
    }
  }

  changeTitle = (e) => {
    this.setState({ title: e.target.value });
  }

  changeHosts = (e) => {
    this.setState({ hosts: e.target.value });
  }

  changePlace = (e) => {
    this.setState({ place: e.target.value });
  }

  changeText = (e) => {
    this.setState({ text: e.target.value });
  }

  setDate = () => {
    console.log('setDate');
  }

  setImage = (e) => {
    console.log('setImage0,', e.target.value);
    const i = e.target.files[0];
    Resizer.imageFileResizer(i, 1000, 500, 'JPEG', 100, 0, image => {
      this.setState({ image });
    });
    //const image = URL.createObjectURL(e.target.files[0]);
  }

  setTimestamp = (timestamp) => {
    this.setState({ timestamp })
  }

  toggleDate = () => {
    let showDate = this.state.showDate;
    showDate ? showDate = false : showDate = true;
    this.setState({ showDate });
  }

  onCreate = () => {
    this.setState({ creating: true });
  }

  closeError = () => {
    this.setState({ errorMsg: '' });
  }

  error = (errorMsg) => {
    this.setState({ errorMsg, creating: false });
  }

  render() {
    return (
      <Wrapper>
        { this.state.showDate &&
          <SelectDateTime
            setTimestamp={this.setTimestamp}
            timestamp={this.state.timestamp}
            toggleDate={this.toggleDate}
          />
        }
        { this.state.creating &&
          <CreateEvent
            title={this.state.title}
            timestamp={this.state.timestamp}
            text={this.state.text}
            image={this.state.image}
            hosts={this.state.hosts}
            place={this.state.place}
            error={this.error}
          />
        }
        <Content animationIn="fadeIn" animationInDuration={2000}>
          <CInner>
          { this.state.errorMsg !== '' && <Error closeError={this.closeError} errorMsg={this.state.errorMsg} /> }
          <EventImage image={this.state.image} setImage={this.setImage} />
          <EventDate timestamp={this.state.timestamp} toggleDate={this.toggleDate} />
          <EventTitle title={this.state.title} changeTitle={this.changeTitle} />
          <EventHosts hosts={this.state.hosts} changeHosts={this.changeHosts} />
          <EventPlace place={this.state.place} changePlace={this.changePlace} />
          <EventText text={this.state.text} changeText={this.changeText} />
          </CInner>
        </Content>
        <ButtonWrapper animationIn="fadeInUp" animationInDuration={2000}>
          <Button onClick={() => this.onCreate()}>
            <AddCircle color="#fff" size={30} style={{ marginRight: 5 }} />
            Create
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default Create;
