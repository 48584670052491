import React from 'react';
import styled from 'styled-components';
import { Download2 } from 'styled-icons/remix-fill/';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Load = styled.p`
  color: #fff;
  font-family: 'arvo';
  font-size: 1.5em;
`

const Loading = () => (
  <Wrapper>
    <div className="animated fadeInDown infinite">
      <Download2 size={40} color="#fff" />
    </div>
    <div className="animated pulse infinite">
      <Load>
        Loading
      </Load>
    </div>
  </Wrapper>
)

export default Loading;
