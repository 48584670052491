import React from 'react';
import styled from 'styled-components';
import { KeyboardReturn } from 'styled-icons/material/';
import { CaretLeft, CaretRight } from 'styled-icons/fa-solid';

const Wrapper = styled.div`
    
`
const InnerWrapper = styled.div`
    display: flex;
`
const Btn = styled.div`
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: rgba(200,200,200,0.9);
    align-items: center;
    justify-content: center;
    display: flex;
    color: #333;
    margin: 10px;
    &:hover {
        background: rgba(200,200,200,0.6);
    }
`
const RBtn = styled(Btn)`
    width: auto;
    min-width: 50px;
`
const Return = styled.div`
  box-sizing: border-box;
  line-height: 40px;
  font-size: 22px;
  text-align: center;
  width: 40px;
  color: #555;
  margin: 0 8px;
  height: 40px;
  border-color: #f2f2f2;
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 10px;
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
  font-family: sans-serif;
  display: inline-block;
  transition: box-shadow 0.3s ease, transform 0.15s ease;
  box-shadow: 0 0 1px #888,0 1px 0 #fff, 0 6px 0 #C0C0C0, 0 8px 17px rgba(#444, 0.4), 2px 1px 4px rgba(#444, 0.25), -2px 1px 4px rgba(#444, 0.25), 0 9px 16px rgba(#444, 0.1);
`
const CurrentStep = styled.p`
    margin: 0 auto;
    text-align: center;
    padding: 0;
`


const JumpTo = ({ setStep, currentStep }) => {
    return (
        <Wrapper className="animated fadeInUp slow">
            <CurrentStep>{currentStep + 1} / 6</CurrentStep>
                <InnerWrapper>
                { currentStep > 0 && <Btn onClick={() => setStep(currentStep - 1)}>
                    <CaretLeft size={35} color="#333" />
                </Btn> }
                { currentStep < 5 && 
                <RBtn onClick={() => setStep(currentStep + 1)}>
                    <CaretRight size={35} color="#333" /> 
                    { currentStep < 3 && 
                    <span> /<Return><KeyboardReturn size={24} color="#333" /></Return></span>
                    }
                </RBtn>
                }
            </InnerWrapper>
        </Wrapper>
    )
}

export default JumpTo;