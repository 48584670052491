import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import './App.css';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import Router from './components/router';

const Wrapper = styled.div`
  background: radial-gradient(#222, #111);
  color: #fff;
  width: 100%;
  height: 100%;
  transition: all 2s;
  background-attachment: fixed;
`

class App extends Component {
  componentWillMount() {
    const config = {
      apiKey: 'AIzaSyBI4AT3SG69MzXVeYB6T7Im4vKSoAp2ydY',
      authDomain: 'birthday-9780a.firebaseapp.com',
      databaseURL: 'https://birthday-9780a.firebaseio.com',
      projectId: 'birthday-9780a',
      storageBucket: 'birthday-9780a.appspot.com',
      messagingSenderId: '127686338783',
      appId: '1:127686338783:web:54078bc3584c57ef0f616a'
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
  }

  render() {
    return (
      <Wrapper>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Wrapper>
    );
  }
}

export default App;
