import React from 'react';
import styled from 'styled-components';
import AllEvents from './allEvents';

const Wrapper = styled.div`
    text-align: center;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.8);
    width: 100%;
    overflow: auto;
    padding: 70px 0;
    box-sizing: border-box;
    z-index: 9997;
`
const H = styled.div`
    font-size: 1.5em;
    padding: 20px;
    box-sizing: border-box;
`

const Dashboard = ({ toggleDashboard }) => (
    <Wrapper className="animated slideInRight">
        <H>Dashboard</H>
        <AllEvents toggleDashboard={toggleDashboard} />
    </Wrapper>
);

export default Dashboard;