import React from 'react';
import styled from 'styled-components';
import moment from 'moment';


const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  margin-top: -100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Button = styled.div`
  cursor: pointer;
  background: rgba(0,0,0,0.8);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`

const Day = styled.div`
  color: #333;
`
const Month = styled.div`
  text-transform: uppercase;
  color: #f55;
`
const Year = styled.div`
  font-size: 12px;
  color: #ddd;
`
const Time = styled.div`

`
const Row = styled.div`

`
const Date = styled.div`
  background: #eee;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
`

const EventImage = ({ toggleDate, timestamp }) => (
  <Wrapper>
    <Button onClick={toggleDate}>
      <Date>
        <Month>{moment(timestamp).format('MMM')}</Month>
        <Day>{moment(timestamp).format('DD')}</Day>
      </Date>
      <Row>
        <Time>{moment(timestamp).format('HH:mm')}</Time>
        { timestamp === 0 ?
          <Year>{moment().format('gggg')}</Year> :
          <Year>{moment(timestamp).format('gggg')}</Year>
        }
      </Row>
    </Button>
  </Wrapper>
)

export default EventImage;
