import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { AddCircle } from 'styled-icons/material';
import { Link } from 'react-router-dom';
import logo from './../../assets/logo.png';
import WhatIs from '../whatIs';

const GlobalStyle = createGlobalStyle`
  @keyframes flyaway {
    0%   { 
      opacity: 1;
    }
    100% { 
      transform: translate3d(200%, -100%, 0) rotate(-20deg) scale3d(0.3, 0.3, 0.3);
      opacity: 0; 
    }
  }

  @keyframes flyIn {
    0% {
      opacity: 0;
      transform: translate3d(-300%, 400px, 0) rotate(-60deg) scale3d(0, 0, 0) rotateX(-60deg);
    }
    100% {
      transform: translate3d(0, 0, 0) rotate(0) scale3d(1, 1, 1);
    }    
  }

  @keyframes floating {
      from { transform: translate(0,  0px) rotate(0); }
      65%  { transform: translate(0, 6px) rotate(2deg); }
      to   { transform: translate(0, -0px) rotate(0); }    
  }

  .floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  .flyaway {
    animation-name: flyaway;
  }
  .flyIn {
    animation-name: flyIn;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const Logo = styled.img`
  width: 150px;
  height: 150px;
`
const ElemWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`



const Button = styled(Link)`
  color: #fff;
  background: rgba(0,0,0,0.5);
  border-radius: 60px;
  padding: 20px;
  display: block;
  text-decoration: none;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &:hover {
    background: rgba(0,0,0,0.8);
  }
`

const Home = () => {

  const [fly, letFly] = useState(false);

  const onFly = () => {
    letFly(true);
    setTimeout(() => { 
      letFly(false);
    }, 700);
  }

  return (
    <Wrapper>
      <GlobalStyle />
      <ElemWrapper className="floating" onClick={() => onFly()}>
        <Logo src={logo} className={ fly ? 'animated flyaway' : 'animated flyIn slower' } />
      </ElemWrapper>
      <ElemWrapper>
        <Button to="/createEvent" className="animated fadeInUp">
          <AddCircle color="#fff" size={40} style={{ marginRight: 5 }} /> Create
        </Button>
      </ElemWrapper>
      <ElemWrapper className="animated fadeInUp">
        <WhatIs />
      </ElemWrapper>
    </Wrapper>
  );
}

export default Home;
