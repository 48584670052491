import React from 'react';
import styled from 'styled-components';
import { Close } from 'styled-icons/remix-line/';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
`
const Hide = styled.div`
  position: fixed;
  top: 25px;
  right: 25px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = ({ children, onClose }) => (
        <Wrapper className="animated fadeInUp">
            <Hide onClick={onClose}><Close color="#fff" size={30}/></Hide>
            {children}
        </Wrapper>
);

export default Overlay;